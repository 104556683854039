import React from 'react'
import CommentBox from '../../components/comments/CommentBox'
import Comment from '../../components/comments/Comment'
import CommentService from '../../api/Comment/Service'
import { scrollToClass } from '../../helpers/LegacyJquery'

class CommentsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: Object.assign([], {}, this.props.comments),
      expanded: false,
      deletionId: '',
    }
    this.submitComment = this.submitComment.bind(this)
    this.toggleExpand = this.toggleExpand.bind(this)
    this.deleteComment = this.deleteComment.bind(this)
    this.showDeleteConfirmation = this.showDeleteConfirmation.bind(this)
  }
  toggleExpand() {
    if (this.state.expanded) {
      scrollToClass('section-comments')
    }
    this.setState({ expanded: !this.state.expanded })
  }
  submitComment(comment_content, parent_id) {
    CommentService.submitComment(
      comment_content,
      parent_id,
      this.props.url,
      this.props.model,
      res => {
        this.setState({ comments: res })
      }
    )
  }
  showDeleteConfirmation(comment_id) {
    this.setState({ deletionId: comment_id }, () => {
      $('#confirm-delete').modal('show')
    })
  }
  deleteComment() {
    CommentService.deleteComment(this.state.deletionId, location.reload.bind(location))
  }
  render() {
    const endIndex = this.state.expanded ? this.state.comments.length : 4
    let comments = this.state.comments
      .slice(0, endIndex)
      .map(comment => (
        <Comment
          key={comment.id}
          current_user={this.props.current_user}
          {...comment}
          submitComment={this.submitComment}
          model={this.props.model}
          showDeleteConfirmation={this.showDeleteConfirmation}
        />
      ))
    return (
      <div>
        {this.props.model === 'Blog' && (
          <div className='blog-post-comments-title'>Comments ({this.state.comments.length})</div>
        )}
        <div className='container-fluid no-padding' id='comments'>
          {this.state.comments.length > 0 && this.props.model === 'Design' && (
            <div className='comment-count-text'>
              {' '}
              {this.state.comments.length} Feedback{this.state.comments.length > 1 && 's'}
            </div>
          )}
          <div>
            <CommentBox
              submitComment={this.submitComment}
              currentUser={this.props.current_user}
              commentCount={this.props.comments.length}
              isCommunityDesign={this.props.is_community_design}
            />
            <div>{comments}</div>
            {this.state.comments.length > 4 && (
              <div
                className='text-center text-underline pointer-cursor'
                onClick={this.toggleExpand}
              >
                {this.state.expanded ? 'Load Less' : 'Load More Rows'}
              </div>
            )}
          </div>
        </div>
        <div
          className='modal fade'
          tabIndex='-1'
          id='confirm-delete'
          role='dialog'
          aria-hidden='true'
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content modal-delete'>
              <div className='container-fluid'>
                <div className='modal-header'>
                  <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true' className='icon-close'></span>
                  </button>
                </div>
                <div className='row modal-row'>Are you sure you want to delete your comment?</div>
              </div>
              <div
                style={{
                  backgroundColor: '#F4F5F6',
                  borderRadius: '8px',
                  position: 'absolute',
                  bottom: '0',
                  width: '100%',
                }}
              >
                <div
                  className='text-center'
                  style={{
                    padding: '22px 45px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <button
                    type='button'
                    className='button button-white'
                    data-dismiss='modal'
                    style={{ margin: '0 15px 0 15px' }}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='button button-warning'
                    style={{ margin: '0 15px 0 15px' }}
                    onClick={this.deleteComment}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default CommentsList
