import React from 'react'
import DesignAddToCartButtonOriginal, {
  Props,
} from '../../components/designs/DesignAddToCartButton'

/**
 * Proxy component so it can be used in .erb files.
 * To be refactored into a rails partial.
 */
const DesignAddToCartButton: React.FC<Props> = ({ ...props }) => {
  return <DesignAddToCartButtonOriginal {...props} />
}
export default DesignAddToCartButton
