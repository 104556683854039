// NOTE: This is a temporary wrapper file to allow use of the DownloadPdfQuoteButton React component in ERB files, by adding the SignInProvider
import React from 'react'
import SignInProvider from '../../components/common/providers/SignInProvider'
import { User } from '../../api/Users/Types'
import DownloadPdfQuoteButton from '../common/DownloadPdfQuoteButton'
import { Design } from '../../api/Designs/Types'

interface Props {
  user?: User
  design: Design
  label: string
  showArrowIcon: boolean
  includeMachineLogicSoftware: boolean
  includeRobotPartnerParts: boolean
  analyticId?: string
}

const ErbDownloadPdfQuoteButton: React.FC<Props> = ({
  user,
  design,
  label = 'PDF Quote',
  showArrowIcon = true,
  includeMachineLogicSoftware,
  includeRobotPartnerParts,
  analyticId,
}) => {
  return (
    <SignInProvider>
      <DownloadPdfQuoteButton
        design={design}
        user={user}
        showArrowIcon={showArrowIcon}
        label={label}
        includeMachineLogicSoftware={includeMachineLogicSoftware}
        includeRobotPartnerParts={includeRobotPartnerParts}
        analyticId={analyticId}
      />
    </SignInProvider>
  )
}

export default ErbDownloadPdfQuoteButton
