import React from 'react'
import ModalOpen from '../../components/common/modals/ModalOpen'
import SignInModal from '../../components/common/modals/SignInModal'
import { SignInConsumer } from '../../components/common/providers/SignInProvider'
import { User } from '../../api/Users/Types'
import requestPdfQuoteModal from '../../helpers/RequestPdfQuoteModal'
import { Design } from '../../api/Designs/Types'
import DesignActionRequiredModal from '../../components/designs/DesignActionRequiredModal'

interface Props {
  user?: User
  label: string
  showArrowIcon: boolean
  includeMachineLogicSoftware: boolean
  includeRobotPartnerParts: boolean
  design: Design
  analyticId?: string
}

const DownloadPdfQuoteButton: React.FC<Props> = ({
  user,
  design,
  label = 'PDF Quote',
  showArrowIcon = true,
  includeMachineLogicSoftware,
  includeRobotPartnerParts,
  analyticId,
}) => {
  const actionRequiredModal = <DesignActionRequiredModal currentUser={user} design={design} />

  const designId = design.id

  return (
    <>
      <SignInConsumer>
        {signInContext => (
          <ModalOpen
            buttonComponent={
              <button className='document-downloads-button' data-analytic-id={analyticId}>
                <i className='icon-proposal download_icon' />
                <span className='document-downloads-button-label'>{label}</span>
                {showArrowIcon && <i className='far fa-arrow-to-bottom' />}
              </button>
            }
            modalComponent={
              design.has_obsolete_parts ? (
                actionRequiredModal
              ) : (
                <SignInModal
                  currentUser={signInContext.currentUser}
                  successCallback={() =>
                    requestPdfQuoteModal(
                      designId,
                      includeMachineLogicSoftware,
                      includeRobotPartnerParts
                    )
                  }
                />
              )
            }
          />
        )}
      </SignInConsumer>
    </>
  )
}

export default DownloadPdfQuoteButton
